.categorias-section {
    padding-left: 15px;
    padding-bottom: 30px;
}

.categorias-titulo {
    font-size: 13px;
    color: #3A3A3A;
    font-weight: bold;
    margin-bottom: 10px; /* Espaço entre o título e o container das categorias */
}

.categorias-container {
    display: flex;
    overflow-x: auto;
    align-items: center; /* Alinha os ícones verticalmente */
}

.categoria {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;
    text-align: center;
}

.categoria:first-child {
    margin-left: 0;
}

.categoria-icone {
    background-color: #508A63;
    color: white;
    border-radius: 10px;
    width: 60px; /* Largura fixa */
    height: 60px; /* Altura fixa */
    font-size: 24px; /* Tamanho da fonte do ícone */
    display: flex;
    align-items: center;
    justify-content: center;
}

.categoria-nome {
    color: #333;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px; /* Espaço entre o ícone e o texto */
}

@media (min-width: 768px) {
    .categorias-section {
        display: flex;
        flex-direction: column; /* Faz com que o título fique acima dos ícones */
        align-items: center; /* Centraliza a seção na tela grande */
    }

    .categorias-container {
        justify-content: center; /* Centraliza o conteúdo */
        padding-left: 0; /* Remove o padding para telas grandes */
    }
}
