.titulo-com-icone {
  display: flex;
  align-items: center;
  color: #7E1761;
  font-weight: bold;
  padding-left: 10px;
}

.titulo-com-icone .fa-icon {
  margin-right: 10px;
}
