.contato-estabelecimento {
    margin: 20px auto;
    padding: 10px;
    max-width: 600px;
    text-align: center;
  }
  
  .contato-estabelecimento h3 {
    margin-bottom: 20px;
    color: #4b4b4b;
    font-size: 1.2em;
  }
  
  .contato-botoes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .contato-botao {
    background-color: #7E1761;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 5px;
    font-size: 1em;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contato-botao svg {
    margin-right: 10px;
  }
  
  .contato-botao:hover {
    background-color: #5b1246;
  }
  
  @media (max-width: 768px) {
    .contato-botao {
      width: 100%;
      justify-content: center;
    }
  }
  