.oferta-completa {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.oferta-completa h1 {
  margin-top: 0;
}

.oferta-completa p {
  margin: 5px 0;
}

.loading {
  text-align: center;
  font-size: 1.5em;
  color: #333;
  margin-top: 20px;
}

.voltar-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Alinha o ícone e o texto verticalmente */
  margin-top: 20px;
  cursor: pointer;
  width: 95%;
}

.voltar-text {
  text-decoration: underline;
  color: #7E7E73;
  font-size: 20px;
  padding-bottom: 4px;
  margin-left: 8px; /* Espaço entre o ícone e o texto */
}