.barra-pesquisa {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-top: 12px;
  position: relative;
  z-index: 2; /* Certifica que a barra de pesquisa está na frente */
}

.barra-pesquisa-input {
  width: 60%;
  padding: 10px 40px 10px 10px; /* Ajuste para incluir espaço para o ícone */
  border: 2px solid #fff; /* Cor original da borda */
  border-radius: 100px;
  font-size: 20px;
  background-color: #F8F8F8;
  transition: border-color 0.3s ease; /* Adiciona uma transição suave */
}

.barra-pesquisa-input:focus {
  border-color: black ; /* Cor mais escura para o foco */
  outline: none; /* Remove o contorno padrão do navegador */
}

.barra-pesquisa-button {
  background-color: #F8F8F8;
  color: black;
  border: none;
  position: absolute;
  right: calc(20% + 10px); /* Posiciona o ícone dentro da barra de pesquisa */
  padding: 10px;
  border-radius: 100px;
}

.inverted-icon {
  transform: scaleX(-1);
  font-size: larger;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  width: 60%;
  background-color: #fff;
  border: 1px solid #C4C4C4;
  border-top: none; /* Remove a borda superior */
  border-radius: 0 0 5px 5px; /* Arredonda apenas os cantos inferiores */
  max-height: 200px;
  overflow-y: auto;
  z-index: 1; /* Garante que a lista de sugestões fique atrás da barra de pesquisa */
  margin-top: -2px; /* Ajusta a posição para ficar por trás da borda da barra de pesquisa */
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .barra-pesquisa-input {
    width: 95%;
    padding: 8px 35px 8px 10px; /* Ajuste para incluir espaço para o ícone */
    font-size: 16px;
  }

  .barra-pesquisa-button {
    right: calc(5% + 10px); /* Posiciona o ícone dentro da barra de pesquisa */
    padding: 8px;
  }

  .suggestions-list {
    width: 95%;
  }
}
