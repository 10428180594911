.regras-cupom {
    margin: 20px auto; /* Centraliza o componente */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%; /* Largura padrão para dispositivos móveis */
    max-width: 700px; /* Limita a largura máxima */
  }
  
  @media (min-width: 768px) {
    .regras-cupom {
      width: 50%; /* Define a largura como 50% para dispositivos maiores */
    }
  }
  
  .regras-cupom h3 {
    margin-bottom: 10px;
    color: #4b4b4b;
  }
  
  .regras-cupom ul {
    list-style-type: disc; /* Define o estilo de lista como discos/pontos */
    padding-left: 20px; /* Adiciona espaço à esquerda para os pontos */
  }
  
  .regras-cupom li {
    margin-bottom: 10px;
    color: #7e7e7e;
  }
  