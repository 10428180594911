.listagem-ofertas-card-resumo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;  
}

.loading {
  text-align: center;
  padding: 10px;
}

.end-message {
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.voltar-inicio {
  cursor: pointer;
  color: black;
  text-decoration: underline;
}

.titulo-listagem-ofertas {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #7E1761;
  font-weight: bold;
  padding-left: 10px;
}