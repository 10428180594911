/* CardEstabelecimento.css */
.card-estabelecimento {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 100px; /* Ajuste o tamanho conforme necessário */
    height: 100px; /* Ajuste o tamanho conforme necessário */
    border-radius: 15px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.30); /* Sombra conforme as configurações do Figma */
    margin: auto;
  }
  
  .card-logo {
    max-width: 80%;
    max-height: 80%;
    height: auto;
  }
  