.oferta-card-resumo {
  display: flex;
  align-items: center;
  border: 0.5px solid #efefef;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  position: relative; /* Para posicionamento absoluto dos filhos */
  width: 98%;
}

.oferta-logo {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.oferta-detalhes {
  flex: 1;
  padding-top: 30px; /* Ajusta este valor conforme necessário */
  padding-left: 5px;
}

.oferta-top-buscas {
  font-size: 11px;
  color: #800080;
}

.oferta-titulo {
  font-size: 21px;
  position: absolute;
  top: 10px; /* Ajusta este valor conforme necessário */
}

.oferta-estabelecimento {
  font-size: 16px;
  color: #555;
}

.oferta-endereco {
  font-size: 14px;
  color: #777;
}

.resgatar-button {
  background-color: #13b13f;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: 700;
}

.resgatar-icone {
  margin-right: 5px;
}
