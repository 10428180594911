@import url('https://fonts.googleapis.com/css?family=Jaldi&display=swap');

.mini-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  position: relative;
  font-family: 'Jaldi', sans-serif;
}

.voltar-icon {
  cursor: pointer;
  color: #545454;
  position: absolute;
  left: 20px;
  font-size: 12px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.logo-bold {
  font-weight: bold;
  color: rgba(79,137,98,1); /* Verde */
  font-size: 40px;
  line-height: 1.5; /* Ajuste para alinhamento vertical */
}

.logo-normal {
  color: rgba(79,137,98,1); /* Verde */
  font-size: 24px;
  margin-left: 5px; /* Espaço entre "Ofertas" e "do Sisal" */
}
