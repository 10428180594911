/* src/components/header/Header.css */
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
}

.header-logo {
  width: 100%;
  max-width: 600px; /* Tamanho máximo da imagem para telas maiores */
  height: auto;
}

@media (max-width: 768px) {
  .header-logo {
    max-width: 400px; /* Tamanho máximo da imagem para telas menores */
  }
}
