.cupom-container {
    text-align: center;
    margin: 20px;
  }
  
  .cupom-card-container {
    position: relative;
    display: inline-block;
  }
  
  .cupom-label {
    position: absolute;
    font-size: 0.8em;
    color: #7E7E73;
    padding: 0 5px;
  }
  
  .cupom-card {
    display: inline-block;
    padding: 10px 20px;
    border: 2px solid #508A63;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .cupom-code {
    font-size: 1.5em;
    font-weight: bold;
    color: #508A63;
    display: inline-block;
  }
  
  .share-button {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7E7E73;
    cursor: pointer;
    font-size: 1.2em;
  }
  
  .share-button:hover {
    color: #508A63;
  }
  
  .share-button svg {
    margin-right: 5px;
  }
  