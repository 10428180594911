.dados-estabelecimento {
    text-align: center;
    margin: 20px;
  }
  
  .estabelecimento-title {
    font-size: 2em;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .estabelecimento-searches {
    color: #7E1761; /* Cor roxa */
    margin: 10px 0;
    font-weight: bold;
  }
  
  .estabelecimento-searches .fa-search {
    margin-right: 5px;
  }
  
  .estabelecimento-distance,
  .estabelecimento-address {
    color: #3A3A3A; /* Cor cinza */
    margin: 5px 0;
  }
  